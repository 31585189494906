import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import SearchInstitution from "root/components/SearchInstitution";

import styles from "./index.module.css";

const SolutionInstitutions = ({ color }) => {
  const { t } = useTranslation();
  const translations = t("home.institutions");

  return (
    <div className={styles.root}>
      <SearchInstitution
        findInstitution={translations.findInstitution}
        askSioslifeForm={translations.askSioslifeForm}
        color={color}
        page="solution"
      />
    </div>
  );
};

SolutionInstitutions.propTypes = {
  color: PropTypes.string.isRequired,
};

export default SolutionInstitutions;
