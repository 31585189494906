import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";
import Typography from "root/components/Typography";
import classNames from "classnames";
import useWindowDimensions from "root/hooks/useWindowDimensions";

import styles from "./index.module.css";

const SolutionValuePropositions = ({
  images: {
    professionals,
    values3,
    wheelChairDT,
    iconGames,
    iconDraw,
    iconMusic,
    iconNews,
    iconPhone,
    iconPhoto,
    iconReligion,
    iconTv,
    values1,
    // eslint-disable-next-line camelcase
    values1_m,
  },
  translations,
  color,
  currentPageNotSolution,
}) => {
  const dimensions = useWindowDimensions();
  const mobileBreakPoint = dimensions.width > 1101;
  const iconClassNames = classNames(styles.icons, styles[color]);
  const login = currentPageNotSolution === true;
  // eslint-disable-next-line camelcase
  const values1Mobile = values1_m ?? values1;

  // eslint-disable-next-line no-console
  console.clear();
  // eslint-disable-next-line no-console
  console.log(!login);

  const renderFeatures = () => {
    if (!login) {
      return (
        <div className={styles.whiteBG}>
          <div className={styles.container} style={{ display: "block" }}>
            <Typography color={color} weight="medium" variant="h3">
              {translations.featuresTitle}
            </Typography>
            {/* FEATURES */}
            <div className={styles.features}>
              <div className={styles.featureContainer}>
                <div className={styles.feature}>
                  <span
                    className={styles.emoji}
                    role="img"
                    aria-label="telephone"
                  >
                    <Img
                      className={iconClassNames}
                      fluid={iconPhone.childImageSharp.fluid}
                      objectFit="cover"
                    />
                  </span>
                  <div>
                    <Typography color="black" weight="medium">
                      {translations.features.title1}
                    </Typography>
                    <Typography color="black">
                      {translations.features.description1}
                    </Typography>
                  </div>
                </div>
                <div className={styles.feature}>
                  <span className={styles.emoji} role="img" aria-label="photo">
                    <Img
                      className={iconClassNames}
                      fluid={iconPhoto.childImageSharp.fluid}
                      objectFit="cover"
                    />
                  </span>
                  <div>
                    <Typography color="black" weight="medium">
                      {translations.features.title2}
                    </Typography>
                    <Typography color="black">
                      {translations.features.description2}
                    </Typography>
                  </div>
                </div>
                <div className={styles.feature}>
                  <span className={styles.emoji} role="img" aria-label="news">
                    <Img
                      className={iconClassNames}
                      fluid={iconNews.childImageSharp.fluid}
                      objectFit="cover"
                    />
                  </span>
                  <div>
                    <Typography color="black" weight="medium">
                      {translations.features.title3}
                    </Typography>
                    <Typography color="black">
                      {translations.features.description3}
                    </Typography>
                  </div>
                </div>
                <div className={styles.feature}>
                  <span className={styles.emoji} role="img" aria-label="draw">
                    <Img
                      className={iconClassNames}
                      fluid={iconDraw.childImageSharp.fluid}
                      objectFit="cover"
                    />
                  </span>
                  <div>
                    <Typography color="black" weight="medium">
                      {translations.features.title4}
                    </Typography>
                    <Typography color="black">
                      {translations.features.description4}
                    </Typography>
                  </div>
                </div>
              </div>
              <div className={styles.featureContainer}>
                <div className={styles.feature}>
                  <span className={styles.emoji} role="img" aria-label="movies">
                    <Img
                      className={iconClassNames}
                      fluid={iconTv.childImageSharp.fluid}
                      objectFit="cover"
                    />
                  </span>
                  <div>
                    <Typography color="black" weight="medium">
                      {translations.features.title5}
                    </Typography>
                    <Typography color="black">
                      {translations.features.description5}
                    </Typography>
                  </div>
                </div>
                <div className={styles.feature}>
                  <span className={styles.emoji} role="img" aria-label="music">
                    <Img
                      className={iconClassNames}
                      fluid={iconMusic.childImageSharp.fluid}
                      objectFit="cover"
                    />
                  </span>
                  <div>
                    <Typography color="black" weight="medium">
                      {translations.features.title6}
                    </Typography>
                    <Typography color="black">
                      {translations.features.description6}
                    </Typography>
                  </div>
                </div>
                <div className={styles.feature}>
                  <span className={styles.emoji} role="img" aria-label="games">
                    <Img
                      className={iconClassNames}
                      fluid={iconGames.childImageSharp.fluid}
                      objectFit="cover"
                    />
                  </span>
                  <div>
                    <Typography color="black" weight="medium">
                      {translations.features.title7}
                    </Typography>
                    <Typography color="black">
                      {translations.features.description7}
                    </Typography>
                  </div>
                </div>
                <div className={styles.feature}>
                  <span
                    className={styles.emoji}
                    role="img"
                    aria-label="religion"
                  >
                    <Img
                      className={iconClassNames}
                      fluid={iconReligion.childImageSharp.fluid}
                      objectFit="cover"
                    />
                  </span>
                  <div>
                    <Typography color="black" weight="medium">
                      {translations.features.title8}
                    </Typography>
                    <Typography color="black">
                      {translations.features.description8}
                    </Typography>
                  </div>
                </div>
              </div>
              {/* IMAGEM */}
              <div className={styles.wellchair}>
                <Img
                  fluid={wheelChairDT.childImageSharp.fluid}
                  className={styles[color]}
                  objectFit="cover"
                />
              </div>
            </div>
          </div>
        </div>
      );
    }

    return <></>;
  };

  return (
    <div className={styles.root}>
      {/* 
      
        SECTION 1

      */}
      <div className={styles.yellowBG}>
        <div className={styles.container}>
          {/* LEFT */}
          <div
            className={styles.left}
            style={mobileBreakPoint ? { height: 0 } : {}}
          >
            <Img
              className={classNames(styles.image1, styles.images)}
              alt="hero"
              fluid={
                mobileBreakPoint
                  ? values1.childImageSharp.fluid
                  : values1Mobile.childImageSharp.fluid
              }
              objectFit="cover"
            />
          </div>
          {/* RIGHT */}
          <div className={styles.right}>
            <div className={styles.textbox}>
              <div className={styles.title}>
                <Typography color={color} weight="medium" variant="h3">
                  {translations.subtitle1}
                </Typography>
                {login ? (
                  <Typography color="blue" weight="medium" variant="h3">
                    {translations.subtitleBlue1}
                  </Typography>
                ) : null}
              </div>
              <div className={styles.description}>
                <Typography color="black">
                  {translations.description1}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* 

        SECTION 2

      */}
      <div className={mobileBreakPoint ? styles.whiteBG : styles.yellowBG}>
        <div className={styles.container}>
          {/* LEFT */}
          <div
            className={styles.left}
            style={mobileBreakPoint ? {} : { order: "2" }}
          >
            <div className={styles.title}>
              <Typography color={color} weight="medium" variant="h3">
                {translations.subtitle2}
              </Typography>
              {login && translations.subtitleBlue2 ? (
                <Typography color="blue" weight="medium" variant="h3">
                  {translations.subtitleBlue2}
                </Typography>
              ) : null}
            </div>
            <div className={styles.description}>
              <Typography color="black">{translations.description2}</Typography>
            </div>
          </div>
          {/* RIGHT */}
          <div
            className={styles.right}
            style={mobileBreakPoint ? { height: 0 } : { order: "1" }}
          >
            <Img
              className={classNames(styles.image2, styles.images)}
              alt="hero"
              fluid={professionals.childImageSharp.fluid}
              objectFit="cover"
            />
          </div>
        </div>
      </div>
      {/* 
      
        SECTION 3

      */}
      {translations.subtitle3 ? (
        <div className={styles.yellowBG}>
          <div className={styles.container}>
            {/* LEFT */}
            <div
              className={styles.left}
              style={mobileBreakPoint ? { height: 0 } : {}}
            >
              <Img
                className={classNames(styles.image3, styles.images)}
                style={mobileBreakPoint ? { top: "-30px" } : {}}
                alt="hero"
                fluid={values3.childImageSharp.fluid}
                objectFit="cover"
              />
            </div>
            {/* RIGHT */}
            <div className={styles.right}>
              <div className={styles.textbox}>
                <div className={styles.title}>
                  <Typography color={color} weight="medium" variant="h3">
                    {translations.subtitle3}
                  </Typography>
                  {login ? (
                    <Typography color="blue" weight="medium" variant="h3">
                      {translations.subtitleBlue3}
                    </Typography>
                  ) : null}
                </div>
                <div className={styles.description}>
                  <Typography color="black">
                    {translations.description3}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      {/* 
      
        FUNCIONALIDADES

      */}
      {renderFeatures()}
    </div>
  );
};

SolutionValuePropositions.propTypes = {
  translations: PropTypes.shape().isRequired,
  color: PropTypes.oneOf(["purple", "red", "green", "yellow"]).isRequired,
  images: PropTypes.shape().isRequired,
  currentPageNotSolution: PropTypes.bool.isRequired,
};

SolutionValuePropositions.defaultProps = {};

export default SolutionValuePropositions;
