import React from "react";
import Img from "gatsby-image";
import PropTypes from "prop-types";
import Grid from "root/components/Grid";
import classNames from "classnames";

import useWindowDimensions from "root/hooks/useWindowDimensions";
import Typography from "root/components/Typography";
import AskSioslifeForm from "root/components/AskSioslifeForm";

import styles from "./index.module.css";

const mobileW = 800;

const SolutionKnowMore = ({ translations, color, image, knowMoreGrid }) => {
  const dimensions = useWindowDimensions();

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <div className={styles.textBlock}>
          <div className={styles.title}>
            <Typography weight="medium" variant="h2" color={color}>
              {translations.title}
            </Typography>
          </div>

          <div className={styles.contact}>
            <AskSioslifeForm color={color} />
          </div>
        </div>
        <div className={styles.imageContainer}>
          <Img
            className={styles.image}
            alt="solution know more"
            fluid={image.childImageSharp.fluid}
            objectFit="cover"
          />

          {dimensions.width > 800 ? (
            <div className={styles.grid}>
              <Grid
                columns={dimensions.width > mobileW ? 36 : 18}
                lines={18}
                color={color}
                opacity={0.5}
              />
            </div>
          ) : (
            <Img
              className={classNames(styles.mobileGrid, styles[color])}
              alt="solution know more"
              fluid={knowMoreGrid.childImageSharp.fluid}
              objectFit="cover"
            />
          )}
        </div>
      </div>
    </div>
  );
};

SolutionKnowMore.propTypes = {
  translations: PropTypes.shape({
    title: PropTypes.string,
  }).isRequired,
  color: PropTypes.string.isRequired,
  knowMoreGrid: PropTypes.shape({
    childImageSharp: PropTypes.shape({
      fluid: PropTypes.func,
    }),
  }).isRequired,
  image: PropTypes.shape({
    childImageSharp: PropTypes.shape({
      fluid: PropTypes.func,
    }),
  }).isRequired,
};

export default SolutionKnowMore;
